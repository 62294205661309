import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { FaHeart, FaClock } from "react-icons/fa";
import { GoRepoForked } from "react-icons/go";
import * as React from 'react';
export default {
  getImage,
  GatsbyImage,
  FaHeart,
  FaClock,
  GoRepoForked,
  React
};